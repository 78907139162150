<template>
  <v-list
    class="pa-0 ma-0 fonte"
    :dark="$theme.dark"
    :light="$theme.light"
    two-line
    dense
    background-color="transparent"
    style="min-height: 20vh; max-height: 90vh; overflow: auto;"
  >
    <template v-for="(cliente, i) in showFiltereds">
      <v-list-item
        @click="open(cliente)"
        style="border-radius: 6px;"
        :key="cliente._id"
        class="fonte pl-2 pr-1 animate__animated animate__fadeInUp"
      >
        <v-list-item-content class="">
          <v-list-item-title class="fonte">
            {{ cliente.nome }}
          </v-list-item-title>
          <v-list-item-subtitle>
            <v-icon color="#333" size="11">mdi-phone</v-icon>
            {{ cliente.telefone }}
          </v-list-item-subtitle>
          <v-list-item-subtitle class="">
            CPF {{ cliente.cpf || "não informado" }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-avatar rounded size="36" color="#f2f2f2">
            <v-icon :color="$theme.primary">mdi-account-circle-outline</v-icon>
          </v-avatar>
        </v-list-item-action>
      </v-list-item>
      <v-divider v-if="i < showFiltereds.length - 1" :key="i"></v-divider>
    </template>
    <v-list-item
      style="min-height: 200px;"
      v-intersect="onIntersect"
    ></v-list-item>
  </v-list>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    filtereds: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      itemsToShow: 12
    };
  },
  computed: {
    ...mapGetters(["get_cliente"]),
    showFiltereds() {
      return this.filtereds.slice(0, this.itemsToShow);
    }
  },
  methods: {
    onIntersect() {
      this.itemsToShow += 12;
    },
    open(c) {
      let client = c;
      if (client.obs) {
        client.has_atipicity = true;
      }
      this.$store.commit("set_cliente", client);
    }
  }
};
</script>
